(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/error-code-handler/assets/javascripts/error-code-handler-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/error-code-handler/assets/javascripts/error-code-handler-constants.js');
"use strict";


const constants = {
  warnings: {
    INVALID_PARAM: 'INVALID_PARAM',
    MEMBER_NOT_FOUND: 'MEMBER_NOT_FOUND',
    CREATOR_BUYIN_FAILED: 'CREATOR_BUYIN_FAILED',
    MAX_GROUPS_REACHED: 'MAX_GROUPS_REACHED',
    MEMBER_ACTIVE: 'MEMBER_ACTIVE',
    ACTIVATED_DRAW_CLOSED: 'ACTIVATED_DRAW_CLOSED',
    NO_SHARES_AVAILABLE: 'NO_SHARES_AVAILABLE',
    OPS_INSUFFICIENT_FUNDS: 'OPS_INSUFFICIENT_FUNDS',
    OPS_ACCOUNT_NOT_REACHABLE: 'OPS_ACCOUNT_NOT_REACHABLE',
    USER_NOT_OWNER_OR_ADMIN: 'USER_NOT_OWNER_OR_ADMIN',
    CONTAINER_NOT_FOUND: 'CONTAINER_NOT_FOUND',
    ACTIVATED_DRAW_NOT_FOUND: 'ACTIVATED_DRAW_NOT_FOUND',
    NO_OPS_REPLY: 'NO_OPS_REPLY',
    NO_ITS_REPLY: 'NO_ITS_REPLY',
    ITS_MAX_AMOUNT_EXCEEDED: 'ITS_MAX_AMOUNT_EXCEEDED',
    MAX_SHARES_PER_MEMBER_REACHED: 'MAX_SHARES_PER_MEMBER_REACHED',
    TOO_FEW_SHARES_SOLD: 'TOO_FEW_SHARES_SOLD',
    GROUP_NAME_ALREADY_EXISTS: 'GROUP_NAME_ALREADY_EXISTS',
    REGBET_DISABLED: 'REGBET_DISABLED',
    NOT_AUTHORISED: 'NOT_AUTHORISED',
    GROUP_NOT_ACTIVE: 'GROUP_NOT_ACTIVE',
    CUSTOMER_NOT_ACTIVE: 'CUSTOMER_NOT_ACTIVE',
    TOKEN_MISSING: 'TOKEN_MISSING',
    ALREADY_MEMBER: 'ALREADY_MEMBER',
    INVITATION_EXPIRED: 'INVITATION_EXPIRED',
    TEAM_FULL: 'TEAM_FULL',
    MAX_GROUPS: 'MAX_GROUPS',
    MINERVA_BUSY: 'MINERVA_BUSY',
    RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
    GROUP_ACTIVE: 'GROUP_ACTIVE',
    BUYIN_CLOSE_BEFORE_CURRENT: 'BUYIN_CLOSE_BEFORE_CURRENT',
    AMOUNT_BELOW_LIMIT: 'AMOUNT_BELOW_LIMIT',
    PLAYER_REACHED_TIMELIMIT: 'PLAYER_REACHED_TIMELIMIT',
    NOT_DEFINED: 'NOT_DEFINED',
    WAGER_MAX_GROUP_AMOUNT_EXCEEDED: 'WAGER_MAX_GROUP_AMOUNT_EXCEEDED',
    CUSTOMER_INACTIVE_ACTIVE_IN_GROUP: 'CUSTOMER_INACTIVE_ACTIVE_IN_GROUP',
    BAD_DRAWNUM: 'BAD_DRAWNUM'
  }
};
setGlobal('svs.components.marketplaceData.errorCodeHandler.constants', constants);

 })(window);