(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/error-code-handler/assets/javascripts/helpers/get-customer-draw-amount-limit.js') >= 0) return;  svs.modules.push('/components/marketplace-data/error-code-handler/assets/javascripts/helpers/get-customer-draw-amount-limit.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const getCustomerDrawAmountLimit = productId => {
  switch (productId) {
    case productIds.CHALLENGE:
      return 10000;
    case productIds.FULLTRAFF:
    case productIds.HVS:
      return 30000;
    case productIds.STRYKTIPSET:
    case productIds.EUROPATIPSET:
    case productIds.MALTIPSET:
    case productIds.BOMBEN:
      return 60000;
    case productIds.T8:
    case productIds.T8STRYK:
    case productIds.T8EXTRA:
    case productIds.POWERPLAY:
      return 20000;
    default:
      return 0;
  }
};
setGlobal('svs.components.marketplaceData.errorCodeHandler.helpers.getCustomerDrawAmountLimit', getCustomerDrawAmountLimit);

 })(window);