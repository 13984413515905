(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/error-code-handler/assets/javascripts/helpers/get-retail-draw-amount-limit.js') >= 0) return;  svs.modules.push('/components/marketplace-data/error-code-handler/assets/javascripts/helpers/get-retail-draw-amount-limit.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const getRetailDrawAmountLimit = productId => {
  switch (productId) {
    case productIds.STRYKTIPSET:
      return 800000;
    case productIds.EUROPATIPSET:
      return 600000;
    case productIds.BOMBEN:
      return 400000;
    case productIds.T8:
    case productIds.T8STRYK:
    case productIds.T8EXTRA:
    case productIds.POWERPLAY:
      return 100000;
    default:
      return 0;
  }
};
setGlobal('svs.components.marketplaceData.errorCodeHandler.helpers.getRetailDrawAmountLimit', getRetailDrawAmountLimit);

 })(window);